
import { adGroupTypeReadAll } from "@/apiClient/adGroupTypeEndpoints";
import { CONSTANTS } from "@/apiClient/constants";
import { searchTermYesterday } from "@/apiClient/searchTermEndpoints";
import { statusList, statusSetStatus, statusParkingLot, statusMultiplayer } from "@/apiClient/statusEndpoints";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { campaignTypeReadAll } from "../../apiClient/campaignTypeEndpoints";
import { categoryReadAll } from "../../apiClient/categoryEndpoints";
import { customerReadAllPretty } from "../../apiClient/customerEndpoints";

// import API

@Component
export default class Nekeyto extends Vue {

    // expose
    private CONSTANTS=CONSTANTS;

    // loading
    private isLoading: boolean = false;
    private hasLoaded: boolean = true;
    private loadingSetStatus: boolean = false;

    // private data
    private statusList: any[] = [];

    // pending
    private pendingAction: boolean = false;
    private pendingStatusLevel = CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_ACTION_TYPE.NONE;

    // Status
    private statusId?: number;
    private statusAnswer?: number = CONSTANTS.STATUS.STATUS_ANSWER.NONE;
    private statusLevel?: number = CONSTANTS.STATUS.STATUS_LEVEL.NONE;
    private statusDestination?: string = "";
    // Negative Action
    private negativeActionText: string = "";
    private negativeActionMatchType?: number = CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_MATCH_TYPE.NONE;
    private negativeActionActionType?: number = CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_ACTION_TYPE.NONE;
    private negativeActionActionDestination?: string = "";

    // Send to parking lot
    private async sendToParkingLot(statusId: number) {
        this.isLoading = true;
        try {
            let result = await statusParkingLot(statusId);
            if (result) {
                // remove front entry
                let newList = this.$store.state.nekeytoCache.nekeytoList;
                this.$store.commit("updateNekeytoCache", {
                    nekeytoList: newList.slice(1)
                });
            }
        }
        catch (error) {
            console.log("ERROR");
        }
    };

    // MOUNTED
    public async mounted() {
        console.log("mounted started");

        // LOAD IN PARALLEL (NEW)
        let allDataPromises = [];
        allDataPromises.push(campaignTypeReadAll());
        allDataPromises.push(categoryReadAll());
        // allDataPromises.push(customerReadAllPretty());
        // allDataPromises.push(adGroupTypeReadAll());
        // @ts-ignore
        await Promise.all(allDataPromises);
    };

    @Watch("$store.state.nekeytoCache.nekeytoList")
    public nekeytoList() {
        return this.$store.state.nekeytoCache.nekeytoList ;
    }

    // API
    public async loadSome() {
        let data = await statusList(CONSTANTS.STATUS.STATUS_ANSWER.NONE);
        if (data) {
            this.$store.commit("updateNekeytoCache", {
                nekeytoList: data
            })
        }
    };
    public async loadSomeParkingLot() {

        // LOAD IN PARALLEL (NEW)
        let allDataPromises = [];
        allDataPromises.push(campaignTypeReadAll());
        allDataPromises.push(categoryReadAll());
        // allDataPromises.push(customerReadAllPretty());
        // allDataPromises.push(adGroupTypeReadAll());
        // @ts-ignore
        await Promise.all(allDataPromises);
        let data = await statusList(CONSTANTS.STATUS.STATUS_ANSWER.PARKING_LOT);
        if (data) {
            this.$store.commit("updateNekeytoCache", {
                nekeytoList: data
            })
        }
    };
    public async loadMultiplayer() {
        let data = await statusMultiplayer();
        if (data) {
            this.$store.commit("updateNekeytoCache", {
                nekeytoList: data
            })
        }
    };

    public discardThisList() {
        // IMPORTANT TO RESET FIRST
        this.reset();
        this.$store.commit("updateNekeytoCache", {
            nekeytoList: []
        });
    };
    public async setStatus() {
        this.loadingSetStatus = true;
        try {
            
            if (this.negativeActionActionType !== CONSTANTS.STATUS.STATUS_LEVEL.NONE) {
                this.statusLevel = this.negativeActionActionType;
            }
            
            if (! (this.negativeActionText.length > 0)) {
                if (this.statusAnswer === CONSTANTS.STATUS.STATUS_ANSWER.GOOD) {

                }
                else {
                    this.loadingSetStatus = false;
                    return;
                }
            }
            if (this.statusLevel === CONSTANTS.STATUS.STATUS_LEVEL.NONE) {

                this.loadingSetStatus = false;
                return; 
            }
            
            this.statusId = this.$store.state.nekeytoCache.nekeytoList[0].id;
            let result;

            if (this.statusAnswer === CONSTANTS.STATUS.STATUS_ANSWER.GOOD) {
                result = await statusSetStatus(
                    this.statusId, 
                    {
                        answer: this.statusAnswer,
                        level: this.statusLevel,
                        destination: this.statusDestination
                    }
                );
            }
            else if(this.statusAnswer === CONSTANTS.STATUS.STATUS_ANSWER.BAD) {
                if (this.negativeActionMatchType === CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_MATCH_TYPE.NONE) {

                    this.loadingSetStatus = false;
                    return; 
                }
                result = await statusSetStatus(
                    this.statusId, 
                    {
                        answer: this.statusAnswer,
                        level: this.statusLevel,
                        destination: this.statusDestination
                    }, 
                    {
                        text: this.negativeActionText?.trim(),
                        matchType: this.negativeActionMatchType,
                        actionType: this.negativeActionActionType,
                        actionDestination: this.negativeActionActionDestination
                    }
                );
            };

            if (result) {
                this.reset();
                // remove front entry
                let newList = this.$store.state.nekeytoCache.nekeytoList;
                this.$store.commit("updateNekeytoCache", {
                    nekeytoList: newList.slice(1)
                });
                if (this.$store.state.nekeytoCache.nekeytoList.length === 0) {
                    await this.loadMultiplayer();
                }
            }
            console.log("About to verify logged in");
            await this.$store.dispatch("verifyLoggedIn");
            console.log("Done verifying logged in");
        } catch (error) {
            console.log(error);
        }
        this.loadingSetStatus = false;
    };

    // LOCAL TOOL MUTATE
    public addWord(word: string) {
        if (this.statusAnswer === CONSTANTS.STATUS.STATUS_ANSWER.BAD) this.negativeActionText += ` ${word}`;
    };
    public setStatusAnswerBad() {
        this.statusAnswer = CONSTANTS.STATUS.STATUS_ANSWER.BAD;
    };
    public setStatusAnswerGood() {
        this.statusAnswer = CONSTANTS.STATUS.STATUS_ANSWER.GOOD;
    };
    public setStatusGoodForMe() {
        this.statusAnswer = CONSTANTS.STATUS.STATUS_ANSWER.GOOD;
        this.statusLevel = CONSTANTS.STATUS.STATUS_LEVEL.CUSTOMER;
    };
    public setStatusGoodForAll() {
        this.statusAnswer = CONSTANTS.STATUS.STATUS_ANSWER.GOOD;
        this.statusLevel = CONSTANTS.STATUS.STATUS_LEVEL.CAMPAIGN_TYPE;
    };
    public setStatusLevel(level: number) {
        this.statusLevel = level;
    };
    public setActionType(actionType: number) {
        this.statusLevel = actionType;
        this.negativeActionActionType = actionType;
    };
    public setMatchType(matchType: number) {
        this.negativeActionMatchType = matchType;
    };
    public reset() {
        this.statusList = this.$store.state.nekeytoCache.nekeytoList;
        // reset status id
        this.statusId = this.$store.state.nekeytoCache.nekeytoList[0].id;
        // reset status
        this.statusAnswer = CONSTANTS.STATUS.STATUS_ANSWER.NONE;
        this.statusLevel = CONSTANTS.STATUS.STATUS_LEVEL.NONE;
        this.statusDestination = "";
        // reset negative action
        this.negativeActionText = "";
        this.negativeActionMatchType = CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_MATCH_TYPE.NONE;
        this.negativeActionActionType = CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_ACTION_TYPE.NONE;
        this.negativeActionActionDestination = "";

        // pending
        this.pendingAction = false;
        this.pendingStatusLevel = CONSTANTS.STATUS.STATUS_LEVEL.NONE;
    };
    public resetNegativeKeyword() {
        this.negativeActionText = "";
    };
    public loadAsNext(searchTermId: any) {
        console.log("updateNekeytoCache");
        this.reset();
        const newList = [];
        let foundItem = null;
        for (let status of this.$store.state.nekeytoCache.nekeytoList) {
            if (status.searchTermId === searchTermId) {
                foundItem = status;
                newList.unshift(status);
            }
            else {
                newList.push(status);
            }
        }
        console.log("updateNekeytoCache");
        this.$store.commit("updateNekeytoCache", {
            nekeytoList: newList
        });
    };

    // LOCAL TOOL READ
    public getNegativeKeywordFormatted() {
        if (this.negativeActionMatchType === CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_MATCH_TYPE.EXACT) return `[${this.negativeActionText?.trim()}]`; 
        if (this.negativeActionMatchType === CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_MATCH_TYPE.BROAD) return `${this.negativeActionText?.trim()}`; 
        if (this.negativeActionMatchType === CONSTANTS.NEGATIVE_ACTION.NEGATIVE_ACTION_MATCH_TYPE.PHRASE) return `"${this.negativeActionText?.trim()}"`; 
        return this.negativeActionText;
    };
    public getCategory() {
        for (let customer of this.$store.state.customerCache.customerList) {
            if (customer.customerId === this.$store.state.nekeytoCache.nekeytoList[0].searchTerm.customerId) {
                return customer.category.name;
            } 
        }
        return "NO CATEGORY SET";
        // return this.$store.state.nekeytoCache.nekeytoList[0].searchTerm.customerId;
    };
    public getMainWebsite() {
        for (let customer of this.$store.state.customerCache.customerList) {
            if (customer.customerId === this.$store.state.nekeytoCache.nekeytoList[0].searchTerm.customerId) {
                return customer.mainWebsite;
            } 
        }
        return false;
    }
    public getCampaignType() {
        for (let customer of this.$store.state.customerCache.customerList) {
            if (customer.customerId === this.$store.state.nekeytoCache.nekeytoList[0].searchTerm.customerId) {
                // return customer.campaign.name;
                for (let campaign of customer.campaigns) {
                    if (campaign.campaignId === this.$store.state.nekeytoCache.nekeytoList[0].searchTerm.campaignId) {
                        if (campaign.campaignType) {
                            return campaign.campaignType.name;
                        }
                        if (campaign.type) {
                            return campaign.type.name;
                        }
                        return "NONAME";
                    }
                }
            }
        }
    };

    // MODAL
    public openModal(statusLevel: number) {
        this.pendingStatusLevel =  statusLevel;
        this.pendingAction = true;
    }
    public async modalYes() {
        this.setActionType(this.pendingStatusLevel)
        // this.statusLevel = this.pendingStatusLevel;
        // await this.setStatus();
        this.pendingAction = false;
    }
    public async modalNo() {
        this.pendingAction = false;
        // this.statusLevel = CONSTANTS.STATUS.STATUS_LEVEL.NONE;
    }

    public isButtonLevelSelected(level: number) {
        if (this.statusLevel === level) {
            return true;
        }
        return false;
    }
    public isButtonMatchTypeSelected(matchType: number) {
        if (this.negativeActionMatchType === matchType) {
            return true;
        }
        return false;
    }
    public async buttonMatchTypeSelected() {

    };

    private searchInNewTab() {
        const searchTerms = this.$store.state.nekeytoCache.nekeytoList[0].searchTerm.text.split(" ").join("+");
        // @ts-ignore
        window.open(`https://www.google.com/search?q=${searchTerms}`, '_blank').focus();
    }
    // @ts-ignore
    private visitWebsite(url: string) {
        // @ts-ignore
        window.open(url, '_blank').focus();
    }

}
